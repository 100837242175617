window.dataLayer = window.dataLayer || [];

/*
      ##    #######   ##     ##  ########   ########   ##    ##   
      ##   ##     ##  ##     ##  ##         ##     ##   ##  ##    
      ##   ##     ##  ##     ##  ##         ##     ##    ####     
      ##   ##     ##  ##     ##  ######     ########      ##      
##    ##   ##  ## ##  ##     ##  ##         ##   ##       ##      
##    ##   ##    ##   ##     ##  ##         ##    ##      ##      
 ######     ##### ##   #######   ########   ##     ##     ##      
*/

$(document).ready(function() {

	/*
	 ######     #######   ##    ##   ########      ###      ######    ########   
	##    ##   ##     ##  ###   ##      ##        ## ##    ##    ##      ##      
	##         ##     ##  ####  ##      ##       ##   ##   ##            ##      
	##         ##     ##  ## ## ##      ##      ##     ##  ##            ##      
	##         ##     ##  ##  ####      ##      #########  ##            ##      
	##    ##   ##     ##  ##   ###      ##      ##     ##  ##    ##      ##      
	 ######     #######   ##    ##      ##      ##     ##   ######       ##      
	*/


	$("#fcontact").submit(function() {
			var data = $(this).serialize() + '&act=envoi';

			$(".formulaire").addClass("form-ok");
			$retour = $(".success-message").show().html("Envoi en cours...");

			$.ajax({
				type    : "POST",
				data    : data,
				dataType: "json",
				url     : "/",
				success : function(response) {
					if(!response.error) {
						window.dataLayer.push({
							'event': 'formSubmitSuccess'
						});	
					}
					$retour.html(response.message);
				},
				error: function() {
					$retour.html("Une erreur est survenue.<br>Merci de réessayer un peu plus tard");
				}
			});
		return false;
	});
});
